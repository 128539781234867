import React from "react";
import {
  IoLogoLinkedin,
  IoLogoFacebook,
  IoLogoYoutube,
  IoMail,
  IoCall,
} from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";

export default function Footer() {
  const Services = [
    {
      id: 1,
      name: "Website Development",
      url: "",
    },
    {
      id: 2,
      name: "Web / Mobile App Development",
      url: "",
    },
    {
      id: 3,
      name: "E - Commerce",
      url: "",
    },
    {
      id: 4,
      name: "Software Development",
      url: "",
    },
    {
      id: 5,
      name: "UI / UX Design",
      url: "",
    },
    {
      id: 6,
      name: "Ai Development",
      url: "",
    },
    {
      id: 7,
      name: "It Consultation",
      url: "",
    },
    {
      id: 8,
      name: "Digital Marketing",
      url: "",
    },
  ];

  const SiteMap = [
    {
      id: 9,
      name: "Our Global Network",
      url: "",
    },
    {
      id: 10,
      name: "Testimonials",
      url: "",
    },
    {
      id: 11,
      name: "Our News",
      url: "",
    },
    {
      id: 12,
      name: "Our Work",
      url: "",
    },
    {
      id: 13,
      name: "Career",
      url: "",
    },
    {
      id: 14,
      name: "Affliate Marketing",
      url: "",
    },
  ];

  const Locations = [
    {
      id: 15,
      name: "United States",
      url: "",
    },
    {
      id: 16,
      name: "Unites Arab Emirates",
      url: "",
    },
    {
      id: 17,
      name: "United Kingdom",
      url: "",
    },
    {
      id: 18,
      name: "Australia",
      url: "",
    },
    {
      id: 19,
      name: "Singapore",
      url: "",
    },
    {
      id: 20,
      name: "Sri Lanka",
      url: "",
    },
  ];
  return (
    <div>
      <div className="flex justify-center sm:px-4 lg:px-20">
        <div className="w-full max-w-default py-8">
          <div className="justify-center sm:flex md:flex lg:hidden">
            <div className="space-y-6 pb-8 border-b">
              <div className="flex justify-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/fitwin%20new%20logo.svg?alt=media&token=4fef0bdc-c993-4fe4-b795-1e407bd6ad7c"
                  alt="logo-fitwin"
                />
              </div>
              <p className="font-body text-body leading-[180%] text-black/[.80] text-center">
                Elevate your digital journey with <br /> our versatile software
                development team.
              </p>
              <div className="text-[1.5rem] space-x-4 flex items-center justify-center">
                <IoLogoLinkedin />
                <AiFillInstagram />
                <IoLogoFacebook />
                <IoLogoYoutube />
              </div>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:pt-10">
            <div className="space-y-6 sm:hidden md:hidden lg:block">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/fitwin%20new%20logo.svg?alt=media&token=4fef0bdc-c993-4fe4-b795-1e407bd6ad7c"
                alt="logo-fitwin"
              />
              <p className="font-body text-body leading-[180%] text-black/[.80]">
                Elevate your digital journey with <br /> our versatile software
                development team.
              </p>
              <div className="text-[1.5rem] space-x-4 flex items-center">
                <IoLogoLinkedin />
                <AiFillInstagram />
                <IoLogoFacebook />
                <IoLogoYoutube />
              </div>
            </div>
            <div className="space-y-4">
              <p className="font-title">Services</p>
              {Services.map((index) => (
                <div key={index.id}>
                  <p className="font-body text-body text-black/[.70] hover:text-secondary hover:cursor-pointer">
                    {index.name}
                  </p>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <p className="font-title">Site Map</p>
              {SiteMap.map((index) => (
                <div key={index.id}>
                  <p className="font-body text-body text-black/[.70] hover:text-secondary hover:cursor-pointer">
                    {index.name}
                  </p>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <p className="font-title">Locations</p>
              {Locations.map((index) => (
                <div key={index.id}>
                  <p className="font-body text-body text-black/[.70]">
                    {index.name}
                  </p>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <p className="font-title">Contact Us</p>
              <div className="flex items-center space-x-2">
                <IoMail className="text-[1.3rem]" />
                <p className="text-body font-body text-black/[.70]">
                  sales@fitwin.co
                </p>
              </div>
              <div className="flex items-top space-x-2">
                <IoCall className="text-[1.3rem]" />
                <div className="space-y-2">
                  <p className="text-body font-body text-black/[.70]">
                    +94 ( 71 ) 170 8063
                  </p>
                  <p className="text-body font-body text-black/[.70]">
                    +94 ( 71 ) 643 8953
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:flex md:flex sm:hidden justify-center border-t p-4 sm:px-4 lg:px-20">
        <div className="w-full max-w-default">
          <div className="flex items-center font-body text-body text-black/[.70]">
            <p className="w-full">
              &copy; Copyright 2023 fitwin.co All Right Recived
            </p>
            <p className="w-full flex justify-end">
              Web Design, Development & Hosting by{" "}
              <a
                className="pl-1 hover:text-secondary font-bold"
                href="https://fitwin.co"
                target="_blank"
                rel="noreferrer"
              >
                Fitwin
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="sm:flex md:hidden lg:hidden justify-center border-t p-4">
        <div className="w-full max-w-default">
          <p className="w-full text-center font-body text-body text-black/[.70]">
            &copy; Copyright 2023 fitwin.co All Right Recived
          </p>
        </div>
      </div>
    </div>
  );
}
