import React from "react";
import { IoMdCheckboxOutline } from "react-icons/io";

export default function Points() {
  return (
    <div className="flex items-center space-x-10">
      <div className="w-full sm:p-4 lg:pl-[12rem] space-y-4">
        <div className="w-full flex justify-center lg:hidden">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fsucess.svg?alt=media&token=a4daa264-dd6c-4868-9cb3-6cd87f8356d6"
            alt=""
            className="w-[15rem]"
          />
        </div>
        <h1 className="font-title lg:text-title sm:text-smalltitle text-primary sm:text-center lg:text-left w-full lg:max-w-[38rem]">
          Every successful application begins with a basic concept.
        </h1>
        <p className="text-body font-body w-full leading-[180%] text-black/[.80] sm:text-center lg:text-left">
          We firmly believe that every breakthrough application originates from
          a simple yet profound idea. That's why we cherish the spark of
          creativity in every client. Whether it's a small concept or a big
          dream, we're here to transform it into reality. Share your vision with
          us, and together, let's craft something extraordinary. Your idea, no
          matter how small, could be the next big thing. Let's innovate
          together!
        </p>
        <div className="flex w-full sm:justify-center lg:justify-start">
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <IoMdCheckboxOutline className="text-[1.3rem]" />
              <p className="text-body font-body w-full leading-[180%] text-black/[.80]">
                Listen attentively, to understanding the core essence of your
                concept
              </p>
            </div>

            <div className="flex items-center space-x-2">
              <IoMdCheckboxOutline className="text-[1.3rem]" />
              <p className="text-body font-body w-full leading-[180%] text-black/[.80]">
                Create a visual representation of your idea for further
                iteration
              </p>
            </div>

            <div className="flex items-center space-x-2">
              <IoMdCheckboxOutline className="text-[1.3rem]" />
              <p className="text-body font-body w-full leading-[180%] text-black/[.80]">
                Build a functional prototype, continuously refining based on
                feedback
              </p>
            </div>

            <div className="flex items-center space-x-2">
              <IoMdCheckboxOutline className="text-[1.3rem]" />
              <p className="text-body font-body w-full leading-[180%] text-black/[.80]">
                Rigorous testing ensures the application meets quality and
                usability standards
              </p>
            </div>

            <div className="flex items-center space-x-2">
              <IoMdCheckboxOutline className="text-[1.3rem]" />
              <p className="text-body font-body w-full leading-[180%] text-black/[.80]">
                Assist in launching the application and provide ongoing
                maintenance and updates
              </p>
            </div>

            <div className="flex items-center space-x-2">
              <IoMdCheckboxOutline className="text-[1.3rem]" />
              <p className="text-body font-body w-full leading-[180%] text-black/[.80]">
                Help scale the application effectively to accommodate growing
                user demands
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full sm:hidden lg:block">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fsucess.svg?alt=media&token=a4daa264-dd6c-4868-9cb3-6cd87f8356d6"
          alt=""
        />
      </div>
    </div>
  );
}
