import React from "react";

const DetailTwo = ({ title, p1, p2, img }) => {
  return (
    <div className="flex justify-center sm:px-4 lg:px-20">
      <div className="w-full max-w-default">
        <div className="flex items-start w-full items-center">
          <div className="w-full justify-start sm:hidden lg:flex">
            <img className="h-[30rem]" alt="right" src={img} />
          </div>

          <div className="space-y-4 w-full md:py-20 sm:pb-0 sm:pt-6">
            <h2 className="sm:text-smalltitle lg:text-title font-title text-primary sm:text-center lg:text-left">
              {title}
            </h2>
            <div className="space-y-4">
              <div className="flex sm:justify-center lg:justify-start">
                <p className="text-body font-body max-w-[700px] w-full text-black/[.80] sm:text-center lg:text-left">
                  {p1}
                </p>
              </div>

              <div className="flex sm:justify-center lg:justify-start">
                <p className="text-body font-body max-w-[700px] w-full text-black/[.80] sm:text-center lg:text-left">
                  {p2}
                </p>
              </div>
            </div>

            <div className="sm:flex justify-center lg:hidden">
              <img className="h-[20rem]" alt="right" src={img} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTwo;
