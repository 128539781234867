import React from "react";
import YourProject from "../components/YourProject"
import Footer from "../components/Footer"
import TopHero from "../components/high_order_components/TopHero";

export default function ContactUs() {
  return (
    <div className="sm:space-y-2 md:space-y-6 lg:space-y-8">
      <TopHero
        title="Contact Us To Talk About Your Fantastic Project"
        description="Unlock the potential of your online business with our tailored
        e-commerce solutions. From seamless user experiences to robust
        backend management, we empower your digital storefront to
        thrive."
        mobile_description="Unlock the potential of your online business with our tailored
        e-commerce solutions."
        buttonText="Let's Get Started"
        buttonLink="/book-an-appointment"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/contact_hero_img.svg?alt=media&token=c86260ec-9cfe-4357-ad7d-ab4efefda775"
      />
      <YourProject/>
      <Footer/>
    </div>
  );
}
