import React from "react";
import Tech from "../../json/Tech.json";

export default function DevTech() {
  return (
    <div className="flex justify-center py-20 sm:px-4 lg:px-20 bg-primary/[.10]">
      <div className="max-w-default w-full sm:space-y-4 lg:space-y-8">
        <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title">
          Technologies We Use
        </h2>
        <div className="flex justify-center">
          <div className="max-w-subtitle w-full">
            <p className="text-center text-body font-body text-center w-full leading-[180%] sm:hidden lg:block text-black/[.80]">
              Elevate your digital journey with our versatile software
              development team. We specialize in web and mobile app development,
              seamlessly integrating e-commerce and digital marketing
              strategies. Your success is Amplify your online presence with our
              e - Business and digital marketing solutions.
            </p>
            <p className="text-center text-body font-body text-center w-full leading-[180%] sm:block lg:hidden text-black/[.80]">
              Elevate your digital journey with our versatile software
              development team. We specialize in web and mobile app development
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-10">
          {Tech.map((index) => (
            <div key={index.id}>
              <img src={index.url} alt={index.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
