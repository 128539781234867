import React, { useState } from "react";
import { IoMdMenu, IoIosArrowDown } from "react-icons/io";
//import { CgMenuGridR } from "react-icons/cg";
import { useNavigate, useLocation } from "react-router-dom";
import MobileMenu from "./MobileMenu";

export default function Header() {
  const MenuItems = [
    {
      id: "1",
      name: "Services",
      url: "/development",
    },
    {
      id: "2",
      name: "E - Commerce",
      url: "/ecommerce",
    },
    {
      id: "3",
      name: "Digital Marketing",
      url: "/digital-marketing",
    },
    {
      id: "4",
      name: "Portfolio",
      url: "/portfolio",
    },
    {
      id: "5",
      name: "About Us",
      url: "/about-us",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [popupVisible, setPopupVisible] = useState(false);

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div className="flex items-center py-3 px-4 z-20 bg-white space-x-4 fixed w-full border-b">
      <div>
        <img
          className="cursor-pointer w-[8rem]"
          onClick={() => navigate("/")}
          src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/fitwin%20new%20logo.svg?alt=media&token=4fef0bdc-c993-4fe4-b795-1e407bd6ad7c"
          alt="logo"
        />
      </div>
      <div className="sm:hidden lg:flex md:hidden items-center space-x-6 w-full justify-start font-title text-body relative z-20">
        {MenuItems.map((index) => (
          <div
            key={index.id}
            onClick={() => navigate(index.url)}
            className={`cursor-pointer hover:text-secondary ${
              location.pathname === index.url ? "text-secondary" : ""
            }`}
          >
            <p>{index.name}</p>
          </div>
        ))}
      </div>

      <div className="sm:hidden lg:flex md:hidden items-center space-x-6 w-full justify-end font-title text-body relative z-20">
        <div
          onClick={() => navigate("/contactus")}
          className="bg-primary text-white p-2 px-4 rounded-md hover:bg-secondary cursor-pointer duration-300"
        >
          <p>Contact Us</p>
        </div>

        {/* <div className="flex items-center space-x-2 hover:cursor-pointer">
          <CgMenuGridR className="text-[1.7rem]" />
          <p>All Products</p>
          <IoIosArrowDown className="text-[1.2rem] pt-1" />
        </div>

        <div
          onClick={() => navigate("/login")}
          className="flex items-center space-x-2 hover:cursor-pointer"
        >
          <p>Sign in</p>
          <img
            alt="signin"
            src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
            className="w-[2rem] rounded-full"
          />
        </div> */}
      </div>

      <div
        onClick={openPopup}
        className="text-[2rem] items-center hover:cursor-pointer justify-end w-full sm:flex lg:hidden md:flex"
      >
        <IoMdMenu />
      </div>

      <MobileMenu
        onClose={closePopup}
        MenuItems={MenuItems}
        popupVisible={popupVisible}
      />
    </div>
  );
}
