import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/App.css";

/* IMPORT COMPONENTS */
import Home from "./pages/Home";
import Ecommerce from "./pages/Ecommerce";
import ContactUs from "./pages/ContactUs";
import Development from "./pages/Development";
import ErrorPage from "./pages/ErrorPage";
import Portfolio from "./pages/Portfolio";
import DigitalMarketing from "./pages/DigitalMarketing";
import Login from "./pages/Login";
import AboutUs from "./pages/AboutUs";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/ecommerce" element={<Ecommerce />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/development" element={<Development />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/digital-marketing" element={<DigitalMarketing />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="*" element={<ErrorPage />} />
        <Route exact path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}
