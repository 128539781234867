import React from "react";
import Projects from "../../json/Projects.json";
import { FiExternalLink } from "react-icons/fi";

export default function Portfolios() {
  const handleExternalLinkClick = (url) => {
    // Open the link in a new browser window
    window.open(url, "_blank");
  };

  return (
    <div className="flex justify-center py-10 sm:px-4 lg:px-20">
      <div className="max-w-default w-full sm:space-y-4 lg:space-y-8">
        <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title">
          Our Work
        </h2>
        <div className="flex justify-center">
          <div className="max-w-subtitle w-full">
            <p className="text-center text-body font-body text-center w-full leading-[180%] sm:hidden lg:block text-black/[.80]">
              Elevate your digital journey with our versatile software
              development team. We specialize in web and mobile app development,
              seamlessly integrating e-commerce and digital marketing
              strategies. Your success is Amplify your online presence with our
              e - Business and digital marketing solutions.
            </p>
            <p className="text-center text-body font-body text-center w-full leading-[180%] sm:block lg:hidden text-black/[.80]">
              Elevate your digital journey with our versatile software
              development team. We specialize in web and mobile app development
            </p>
          </div>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 sm:gap-2 md:gap-2 lg:gap-6">
          {Projects.map((index) => (
            <div
              style={{
                backgroundImage: `url(${index.bg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              key={index.id}
              className="w-full rounded-lg h-[20rem]"
            >
              <div className="bg-gradient-to-t from-black w-full h-full flex justify-start items-end font-title text-white capitalize p-4 rounded-lg">
                <div className="space-y-2">
                  <p>{index.name}</p>
                  <p className="font-body text-body text-white/[.60]">
                    {index.description}
                  </p>
                  {/* <div
                    className="flex items-center bg-black_on hover:bg-black_on_hover w-fit p-2 px-4 rounded-sm space-x-2 cursor-pointer"
                    onClick={() => handleExternalLinkClick(index.url)}
                  >
                    <FiExternalLink className="text-[1.2rem]" />
                    <p className="font-body text-body">Visit Website</p>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
