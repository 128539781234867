import React from "react";

export default function ProjectLive() {
  return (
    <div className="flex justify-center sm:px-4 lg:px-20 bg-black text-white pb-20 sm:pt-20">
      <div className="w-full max-w-default lg:flex sm:block lg:space-x-4 items-center w-full space-y-4">
        <div className="space-y-4 w-full">
          <h2 className="sm:text-smalltitle lg:text-title font-title sm:text-center lg:text-left">
            Keep Your Project Live Without<br/> Downtime
          </h2>
          <div className="flex sm:justify-center lg:justify-start">
            <p className="w-full max-w-[600px] text-body font-body text-white/[.80] sm:text-center lg:text-left">
              our goal is to create a product and service that you’re satisfied
              with and use it every day. This is why we’re constantly working on
              our services to make it better every day and really listen to what
              our clients has to say
            </p>
          </div>
        </div>

        <div className="sm:block md:flex md:space-x-4 sm:justify-center lg:justify-end sm:space-y-4 md:space-y-0 w-full">
          <div className=" flex md:w-fit sm:w-fill">
            <div className="space-y-4">
              <div className="space-y-2 bg-black_on rounded-lg p-4">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/99%20white.svg?alt=media&token=a11b8342-c81b-422c-8b34-366bf849615c"
                  alt="live"
                />
                <p className="font-title lg:text-subtitle sm:text-smallsubtitle">
                  99% server Up Time
                </p>
                <p className="font-body text-body leading-[180%] text-white/[.80] w-full md:max-w-[300px] ">
                  Lorem Ipsum is simply dummy It was popularised in the 1960s
                  with the release of Letraset sheets containing Lorem Ipsum
                  passages.
                </p>
              </div>

              <div className="space-y-2 bg-black_on rounded-lg p-4">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Auto%20Scale%20white.svg?alt=media&token=2427e55d-cd6c-42b0-a9e3-24e07daf50dc"
                  alt="live"
                />
                <p className="font-title lg:text-subtitle sm:text-smallsubtitle">
                  Scalability
                </p>
                <p className="font-body text-body leading-[180%] text-white/[.80] w-full md:max-w-[300px]">
                  Lorem Ipsum is simply dummy It was popularised in the 1960s
                  with the release of Letraset sheets containing Lorem Ipsum
                  passages.
                </p>
              </div>
            </div>
          </div>

          <div className="w-fit lg:pt-40 sm:pt-0">
            <div className="space-y-4">
              <div className="space-y-2 bg-black_on rounded-lg p-4">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/low%20cost%20white.svg?alt=media&token=85897871-bcd3-429f-a825-8dec7a30e680"
                  alt="live"
                />
                <p className="font-title lg:text-subtitle sm:text-smallsubtitle">
                  Low Cost Maintanance
                </p>
                <p className="font-body text-body leading-[180%] text-white/[.80] w-full md:max-w-[300px]">
                  Lorem Ipsum is simply dummy It was popularised in the 1960s
                  with the release of Letraset sheets containing Lorem Ipsum
                  passages.
                </p>
              </div>

              <div className="space-y-2 bg-black_on rounded-lg p-4">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/daily%20reports%20white.svg?alt=media&token=7e5b4478-e13f-4800-a46a-8a5e3f72da13"
                  alt="live"
                />
                <p className="font-title lg:text-subtitle sm:text-smallsubtitle">
                  Daily Reports
                </p>
                <p className="font-body text-body leading-[180%] text-white/[.80] w-full md:max-w-[300px]">
                  Lorem Ipsum is simply dummy It was popularised in the 1960s
                  with the release of Letraset sheets containing Lorem Ipsum
                  passages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
