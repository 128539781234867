import React from "react";

const OurClients = ({ title, description, mobile_description , data, bg }) => {

    return (
        <div className={`flex justify-center ${bg ? "bg-primary/[.10]" : "bg-white"} sm:py-8 lg:py-16 sm:px-4 lg:px-20`}>
            <div className="max-w-default w-full sm:space-y-4 lg:space-y-8">
                <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title">
                    {title}
                </h2>
                <div className="flex justify-center">
                    <div className="max-w-subtitle w-full">
                        <p className="text-center text-body font-body text-center w-full leading-[180%] text-black/[.80] sm:hidden lg:block">
                            {description}
                        </p>
                        <p className="text-center text-body font-body text-center w-full leading-[180%] text-black/[.80] sm:block lg:hidden">
                            { mobile_description}
                        </p>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="w-full max-w-logos">
                        <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-3 gap-10">
                            {data.map((index) => (
                                <div key={index.id} className="flex items-center">
                                    <img src={index.url} alt={index.name} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurClients;