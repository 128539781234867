import React from "react";

const GlobalNetwork = ({ title, description, mobile_description, data}) => {
  return (
    <div className="flex justify-center sm:px-4 lg:px-20 py-10">
      <div className="max-w-default w-full sm:space-y-4 lg:space-y-6">
        <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title">
          {title}
        </h2>
        <div className="flex justify-center">
          <div className="max-w-subtitle w-full">
            <p className="text-center text-body font-body text-center w-full leading-[180%] text-black/[.80] sm:hidden lg:block">
              {description}
            </p>
            <p className="text-center text-body font-body text-center w-full leading-[180%] text-black/[.80] sm:block lg:hidden">
              {mobile_description}
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 sm:gap-2 md:gap-2 lg:gap-6">
          {data.map((index) => (
            <div
              style={{
                backgroundImage: `url(${index.img})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              key={index.id}
              className="w-full rounded-lg"
            >
              <div className="bg-black/[.40] w-full h-[13rem] flex justify-center items-center rounded-lg font-title text-white capitalize">
                <p>{index.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlobalNetwork;
