import React from 'react'
import Portfolios from '../components/portfolio/Portfolios'
import Footer from '../components/Footer'
import TopHero from '../components/high_order_components/TopHero'

export default function Portfolio() {
  return (
    <div>
      <TopHero
        title="Our Portfolio Builds Visibility of What We Can Do"
        description="Unlock the potential of your online business with our tailored
        e-commerce solutions. From seamless user experiences to robust
        backend management, we empower your digital storefront to
        thrive."
        mobile_description="Unlock the potential of your online business with our tailored
        e-commerce solutions."
        buttonText="Let's Get Started"
        buttonLink="/book-an-appointment"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/contact_hero_img.svg?alt=media&token=c86260ec-9cfe-4357-ad7d-ab4efefda775"
        ButtonVisibility="true"
      />
        <Portfolios/>
        <Footer/>
    </div>
  )
}
