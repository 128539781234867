import React from "react";
import Projects from "../../json/Projects.json";
import { FiExternalLink } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function OurWork() {
  const handleExternalLinkClick = (url) => {
    // Open the link in a new browser window
    window.open(url, "_blank");
  };

  const navigate = useNavigate();

  return (
    <div className="flex justify-center py-10 sm:px-4 lg:px-20 bg-primary/[.10]">
      <div className="max-w-default w-full sm:space-y-4 lg:space-y-6">
        <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title">
          Our Work
        </h2>
        <div className="flex justify-center">
          <div className="max-w-subtitle w-full">
            <p className="text-center text-body font-body text-center w-full leading-[180%] text-black/[.80] sm:hidden lg:block">
              Explore a curated selection of our finest works, each a testament
              to our dedication to creativity and excellence. From sleek designs
              to innovative solutions, immerse yourself in the possibilities of
              what we can create together.
            </p>
            <p className="text-center text-body font-body text-center w-full leading-[180%] text-black/[.80] sm:block lg:hidden">
              Explore a curated selection of our finest works, each a testament
              to our dedication to creativity and excellence.
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 sm:gap-2 md:gap-2 lg:gap-6">
          {Projects.map((index) => (
            <div
              style={{
                backgroundImage: `url(${index.bg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              key={index.id}
              className="w-full rounded-lg h-[20rem]"
            >
              <div className="bg-gradient-to-t from-black w-full h-full flex justify-start items-end font-title text-white capitalize p-4 rounded-lg">
                <div className="space-y-2">
                  <p>{index.name}</p>
                  <p className="font-body text-body text-white/[.60]">
                    {index.description}
                  </p>
                  <div
                    className="flex items-center bg-black_on hover:bg-black_on_hover w-fit p-2 px-4 rounded-sm space-x-2 cursor-pointer"
                    onClick={() => handleExternalLinkClick(index.url)}
                  >
                    <FiExternalLink className="text-[1.2rem]" />
                    <p className="font-body text-body">Visit Website</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          onClick={() => {
            navigate("/portfolio");
            window.scrollTo(0, 0); // Scroll to the top of the page after navigation
          }}
          className="flex items-center text-secondary font-body text-body space-x-2 justify-end cursor-pointer"
        >
          <p>View All</p>
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
}
