import React from "react";
import Footer from "../components/Footer";
import TopHero from "../components/high_order_components/TopHero";
import Services from "../components/high_order_components/Services";
import Services_data from "../json/WhatYouGet.json";
import Delivery from "../components/ecommerce/Delivery";
import Points from "../components/ecommerce/Points";
import Process from "../components/ecommerce/Process";
import YourProject from "../components/YourProject";
import DetailOne from "../components/high_order_components/DetailOne";
import DetailTwo from "../components/high_order_components/DetailTwo";

export default function Ecommerce() {
  return (
    <div className="sm:space-y-2 md:space-y-6 lg:space-y-8">
      <TopHero
        title="Fitwin E-commerce Solutions Simplify Online Selling"
        description="Unlock the potential of your online business with our tailored
        e-commerce solutions. From seamless user experiences to robust
        backend management, we empower your digital storefront to
        thrive."
        mobile_description="Unlock the potential of your online business with our tailored
        e-commerce solutions."
        buttonText="Let's Get Started"
        buttonLink="/book-an-appointment"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/ecom_hero_img.svg?alt=media&token=5da68ef8-311d-4f21-9e5c-682f2d8545bd"
        ButtonVisibility="true"
      />
      <Services
        title="What We Offer"
        description="Experience the difference with our comprehensive e-commerce package. Enjoy unparalleled reliability with a 99% uptime guarantee, intuitive control through our admin dashboard, and cutting-edge AI integration for enhanced functionality. Dive deeper into your business insights with seamless access to analytics, putting you in the driver's seat of your online success."
        mobile_description="Experience the difference with our comprehensive e-commerce
        package. Enjoy unparalleled reliability with a 99% uptime
        guarantee, intuitive control through our admin dashboard, and
        cutting-edge AI integration for enhanced functionality."
        data={Services_data}
      />
      <Delivery />
      <Points />
      <Process />
      <DetailTwo
        title="Admin Dashboard & View Analytics"
        p1="Aligning to the modern needs of application development, we use the power of innovation and leverage the most robust technologies to build high-performance, secure, and responsive applications that run seamlessly across all platforms. ​"
        p2="All mobile applications are built based on profound market research and a thorough understanding of our client's business case. We create these native apps with enhanced technology features, faster load application times, and responsive navigation."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fapp-development-illustration.jpg?alt=media&token=a57e76ed-7c3a-4642-b23f-5df90f131e19"
      />
      <DetailOne
        title="Search Engine Optimization & Payment Getaways"
        p1="Cloud Solutions involve leveraging cloud computing technologies to deliver various computing services over the internet, including storage, servers, databases, networking, software, and analytics. Companies utilize platforms like AWS, Azure, and GCP instead of maintaining physical hardware. Cloud Solutions offer scalability, flexibility, cost-effectiveness, and accessibility, enabling businesses to innovate faster and focus on our Client."
        p2="Project Maintenance, on the other hand, is the ongoing process of managing and updating software applications or systems after their initial development and deployment. It involves tasks such as bug fixes, security patches, performance optimization, feature enhancements, and compliance updates. Effective project maintenance ensures that software remains functional, secure, and aligned with evolving business needs over time"
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fdevops-team-abstract-concept-vector-illustration-software-development-team-member-agile-workflow-devops-team-model-it-teamwork-project-management-integrated-practice-abstract-metaphor_335657-2299.avif?alt=media&token=0d7ed965-2d9d-456a-b1bf-db222132aad6"
      />
      <DetailTwo
        title="Mobile Application for your E - Commerce Web App"
        p1="Aligning to the modern needs of application development, we use the power of innovation and leverage the most robust technologies to build high-performance, secure, and responsive applications that run seamlessly across all platforms. ​"
        p2="All mobile applications are built based on profound market research and a thorough understanding of our client's business case. We create these native apps with enhanced technology features, faster load application times, and responsive navigation."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fapp-development-illustration.jpg?alt=media&token=a57e76ed-7c3a-4642-b23f-5df90f131e19"
      />
      <YourProject />
      <Footer />
    </div>
  );
}
