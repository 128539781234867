import React from "react";
import DevTech from "../components/development/DevTech";
import DevAbleTo from "../components/development/DevAbleTo";
import YourProject from "../components/YourProject";
import OurWork from "../components/home/OurWork";
import Footer from "../components/Footer";
import ProjectLive from "../components/development/ProjectLive";
import TopHero from "../components/high_order_components/TopHero";
import Services from "../components/high_order_components/Services";
import Services_data from "../json/Services.json";
import DetailOne from "../components/high_order_components/DetailOne";
import DetailTwo from "../components/high_order_components/DetailTwo";

export default function Development() {
  return (
    <div className="sm:space-y-2 md:space-y-6 lg:space-y-8">
      <TopHero
        title="Every Kind Of Development in One Place"
        description="Unlock the potential of your online business with our tailored
        e-commerce solutions. From seamless user experiences to robust
        backend management, we empower your digital storefront to
        thrive."
        mobile_description="Unlock the potential of your online business with our tailored
        e-commerce solutions."
        buttonText="Let's Get Started"
        buttonLink="/book-an-appointment"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/dev-hero-img.svg?alt=media&token=7658f35a-49cc-449a-82aa-e2fdcac2d5ad"
        ButtonVisibility="true"
      />
      <Services
        title="What We Do"
        description="Our committed team of developers, designers, and marketers is
        focused on delivering exceptional solutions tailored to your
        needs. Utilizing cutting-edge technologies, we craft responsive
        websites and intuitive mobile apps to provide seamless user
        experiences that captivate and connect with your audience."
        mobile_description="Our committed team of developers, designers, and marketers is
        focused on delivering exceptional solutions tailored to your
        needs.F"
        data={Services_data}
      />
      <DevTech />
      <DetailOne
        title="Custom Web Application Development"
        p1="We deliver fully functional, customized, secure, and scalable web applications using the right technology stacks that align with your business’s growth objectives."
        p2="Fitwin web application development services include prototyping, designing, development, testing, technical support, and maintenance. As part of our custom service mix, we also offer technology consultation, modernization, and migration of legacy architectures to help our partners align with evolving user requirements."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Right%20Architecture.svg?alt=media&token=d2977952-c15d-42e5-a122-9e8f618f50bc"
      />
      <DetailTwo
        title="Custom Mobile Application Development"
        p1="Aligning to the modern needs of application development, we use the power of innovation and leverage the most robust technologies to build high-performance, secure, and responsive applications that run seamlessly across all platforms. ​"
        p2="All mobile applications are built based on profound market research and a thorough understanding of our client's business case. We create these native apps with enhanced technology features, faster load application times, and responsive navigation."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fapp-development-illustration.jpg?alt=media&token=a57e76ed-7c3a-4642-b23f-5df90f131e19"
      />
      <DetailOne
        title="Cloud Solutions & Project Maintenance"
        p1="Cloud Solutions involve leveraging cloud computing technologies to deliver various computing services over the internet, including storage, servers, databases, networking, software, and analytics. Companies utilize platforms like AWS, Azure, and GCP instead of maintaining physical hardware. Cloud Solutions offer scalability, flexibility, cost-effectiveness, and accessibility, enabling businesses to innovate faster and focus on our Client."
        p2="Project Maintenance, on the other hand, is the ongoing process of managing and updating software applications or systems after their initial development and deployment. It involves tasks such as bug fixes, security patches, performance optimization, feature enhancements, and compliance updates. Effective project maintenance ensures that software remains functional, secure, and aligned with evolving business needs over time"
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fdevops-team-abstract-concept-vector-illustration-software-development-team-member-agile-workflow-devops-team-model-it-teamwork-project-management-integrated-practice-abstract-metaphor_335657-2299.avif?alt=media&token=0d7ed965-2d9d-456a-b1bf-db222132aad6"
      />
      <DetailTwo
        title="Digital Marketing & Business Growth"
        p1="Aligning to the modern needs of application development, we use the power of innovation and leverage the most robust technologies to build high-performance, secure, and responsive applications that run seamlessly across all platforms. ​"
        p2="All mobile applications are built based on profound market research and a thorough understanding of our client's business case. We create these native apps with enhanced technology features, faster load application times, and responsive navigation."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Flight-bulb-concept-illustration_114360-4416.jpg?alt=media&token=cecc09b1-b82d-4673-92bf-ecae78d43db2"
      />
      <DetailOne
        title="Artificial Intelligence Development & Integration"
        p1="Cloud Solutions involve leveraging cloud computing technologies to deliver various computing services over the internet, including storage, servers, databases, networking, software, and analytics. Companies utilize platforms like AWS, Azure, and GCP instead of maintaining physical hardware. Cloud Solutions offer scalability, flexibility, cost-effectiveness, and accessibility, enabling businesses to innovate faster and focus on our Client."
        p2="Project Maintenance, on the other hand, is the ongoing process of managing and updating software applications or systems after their initial development and deployment. It involves tasks such as bug fixes, security patches, performance optimization, feature enhancements, and compliance updates. Effective project maintenance ensures that software remains functional, secure, and aligned with evolving business needs over time"
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fai-powered-content-creation-isometric-concept-with-chatbot-laptop-screen-3d-vector-illustration_1284-82523.jpg?alt=media&token=52709bcf-c4f4-4177-b4b9-7dd654ff6ae6"
      />
      <ProjectLive />
      <DevAbleTo />
      <OurWork />
      <YourProject />
      <Footer />
    </div>
  );
}
