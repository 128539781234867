import React from "react";
import { MdOutlineRateReview } from "react-icons/md";
import Reviews from "../../json/Reviews.json";

export default function Community() {
  return (
    <div>
      <div className="sm:hidden lg:flex justify-center bg-black text-white h-screen overflow-hidden sm:px-4 lg:px-20">
        <div className="max-w-default w-full">
          <div className="flex items-center h-full space-x-4">
            <div className="space-y-6 w-full">
              <h1 className="font-title text-title">
                We believe in the <br /> power of community
              </h1>
              <p className="w-full max-w-[750px] text-body font-body text-white/[.60]">
                Client testimonials speak volumes about the transformative power
                of our team. Their words illuminate the impact our
                services have had on their lives, from breakthrough moments to
                newfound perspectives. Each testimonial fuels our commitment to
                excellence, driving us to continue being a catalyst for positive
                change.
              </p>
              {/* <div className="flex items-center space-x-4">
                <div className="bg-secondary w-fit p-3 px-6 rounded-md text-body">
                  <p className="text-body font-body">Read all testimonials</p>
                </div>
                <div className="flex items-center space-x-2 text-white/[.60] hover:text-white hover:cursor-pointer">
                  <MdOutlineRateReview className="text-[1.3rem]" />
                  <p className="text-body font-body">Add your review</p>
                </div>
              </div> */}
            </div>

            <div className="flex space-x-4 w-full justify-end">
              <div>
                {Reviews.slice(0,5).map((index) => (
                  <div key={index.id}>
                    <div className="bg-black_on p-4 my-4 rounded-md">
                      <div className="flex items-center space-x-4">
                        <div className="flex items-center">
                          <div>
                            <div className="flex items-center space-x-2">
                              <img
                                src={index.image_url}
                                alt={index.name}
                                className="rounded-full h-[3rem] w-[3rem]"
                              />
                              <div>
                                <p className="font-title capitalize">
                                  {index.name}
                                </p>
                                <p className="font-body text-body text-white/[.60]">
                                  {index.designation}
                                </p>
                              </div>
                            </div>
                            <div className="space-y-4 mt-4">
                              <p className="text-body font-body w-[280px] text-white/[.60] leading-[180%]">
                                {index.description}
                              </p>
                              <div className="bg-white/[.15] h-[1px]"></div>
                              <p className="text-body font-body text-white/[.60]">
                                {index.date}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="pt-[8rem]">
                {Reviews.slice(5,10).map((index) => (
                  <div key={index.id}>
                    <div className="bg-black_on p-4 my-4 rounded-md">
                      <div className="flex items-center space-x-4">
                        <div className="flex items-center">
                          <div>
                            <div className="flex items-center space-x-2">
                              <img
                                src={index.image_url}
                                alt={index.name}
                                className="rounded-full h-[3rem] w-[3rem]"
                              />
                              <div>
                                <p className="font-title capitalize">
                                  {index.name}
                                </p>
                                <p className="font-body text-body text-white/[.60]">
                                  {index.designation}
                                </p>
                              </div>
                            </div>
                            <div className="space-y-4 mt-4">
                              <p className="text-body font-body w-[280px] text-white/[.60] leading-[180%]">
                                {index.description}
                              </p>
                              <div className="bg-white/[.15] h-[1px]"></div>
                              <p className="text-body font-body text-white/[.60]">
                                {index.date}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black p-4 text-white sm:hidden lg:hidden">
        <div className="space-y-6">
          <h1 className="font-title text-smalltitle text-center">
            We believe in the <br /> power of community
          </h1>
          <div className="flex w-full justify-center">
            <p className="w-full max-w-[750px] text-body font-body text-white/[.60] text-center">
              Elevate your digital journey with our versatile software
              development team. We specialize in web and mobile app development,
              seamlessly integrating e-commerce and digital marketing
              strategies. Your success is Amplify your online presence with our
              e - Business and digital marketing solutions.
            </p>
          </div>

          {/* <div className="flex items-center justify-center w-full">
            <div className="space-y-4">
              <div className="bg-secondary w-fit p-3 px-6 rounded-md text-body w-fit">
                <p className="text-body font-body">Read more testimonials</p>
              </div>
              <div className="flex items-center justify-center space-x-2 text-white/[.60] hover:text-white hover:cursor-pointer">
                <MdOutlineRateReview className="text-[1.3rem]" />
                <p className="text-body font-body">Add your review</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
