import React from "react";
import { IoIosCall } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function YourProject() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center sm:px-4 lg:px-20">
      <div className="w-full max-w-default">
        <div className="lg:flex sm:block items-start w-full items-center lg:space-x-4">
          <div className="space-y-4 w-full md:py-8 sm:pb-0 sm:pt-6">
            <h2 className="sm:text-smalltitle lg:text-[2.5rem] font-title text-primary sm:text-center lg:text-left">
              Take your Transformational <br /> Journey with Us!
            </h2>
            <div className="flex sm:justify-center lg:justify-start">
              <p className="text-body font-body max-w-[700px] w-full text-black/[.80] sm:text-center lg:text-left">
                Got a project in mind or need solutions for your business? Reach
                out and let's make it happen together. <br />
                We're here to listen and bring your ideas to life!
              </p>
            </div>

            <div className="flex sm:justify-center lg:justify-start">
              <div className="space-y-2 bg-white w-full max-w-[30rem] rounded-md ">
                <div className="space-y-2">
                  <p className="font-body text-body">
                    Name <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    className="p-3 border rounded-md w-full max-w-[30rem] outline-none"
                    placeholder="Enter your name"
                  />
                </div>

                <div className="space-y-2">
                  <p className="font-body text-body">
                    Email Address <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    className="p-3 border rounded-md w-full max-w-[30rem] outline-none"
                    placeholder="Enter your email address"
                  />
                </div>

                <div className="space-y-2">
                  <p className="font-body text-body">
                    Contact Number ( with country code ){" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    className="p-3 border rounded-md w-full max-w-[30rem] outline-none"
                    placeholder="Enter your contact number"
                  />
                </div>

                <div className="space-y-2">
                  <p className="font-body text-body">
                    Message <span className="text-red-500">*</span>
                  </p>
                  <textarea
                    type="text"
                    className="p-3 border rounded-md w-full max-w-[30rem] outline-none h-[8rem]"
                    placeholder="Enter your message"
                  />
                </div>

                <div className="flex items-center sm:justify-center lg:justify-start font-title text-body space-x-4 hover:cursor-pointer">
                  <div
                    onClick={() => {
                      navigate("/book-an-appointment");
                      window.scrollTo(0, 0);
                    }}
                    className="bg-primary text-white p-3 px-8 rounded-md flex items-center space-x-3"
                  >
                    <p>Submit</p>
                  </div>
                  {/* <div className="flex space-x-2">
              <IoIosCall className="text-[1.3rem]" />
              <p>Contact Us</p>
            </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full space-y-6">
            <h2 className="sm:text-smalltitle lg:text-[1.6rem] font-title text-primary sm:text-center lg:text-left">
              What Happens Next?
            </h2>
            <div className="flex sm:justify-center lg:justify-start">
              <div className="space-y-4 w-full max-w-[40rem]">
                <div className="flex items-center space-x-4">
                  <div className="bg-primary text-white flex items-center justify-center w-[5rem] h-[5rem] text-[2rem]">
                    <p>1</p>
                  </div>
                  <p className="w-full">
                    Our business consultant will reach out to you in a few days
                    following an analysis of your project requirement
                  </p>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="bg-primary text-white flex items-center justify-center w-[5rem] h-[5rem] text-[2rem]">
                    <p>2</p>
                  </div>
                  <p className="w-full">
                    In the meantime, we will sign an NDA to ensure utmost
                    security and confidentiality
                  </p>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="bg-primary text-white flex items-center justify-center w-[5rem] h-[5rem] text-[2rem]">
                    <p>3</p>
                  </div>
                  <p className="w-full">
                  Our business expert will present you with project estimates, process guidelines and estimated timelines
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
