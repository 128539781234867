import React from "react";
import Tech from "../../json/Tech.json";

export default function TechnologiesWeUse() {
  return (
    <div className="flex justify-center py-10 sm:px-4 lg:px-20">
      <div className="max-w-default w-full sm:space-y-4 lg:space-y-8">
        <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title">
          Technologies We Use
        </h2>
        <div className="flex justify-center">
          <div className="max-w-subtitle w-full">
            <p className="text-center text-body font-body text-center w-full leading-[180%] sm:hidden lg:block text-black/[.80]">
              We harness cutting-edge technologies to create seamless websites.
              Our process focuses on efficiency, security, and scalability,
              ensuring optimal digital experiences for our clients and users
              alike.
            </p>
            <p className="text-center text-body font-body text-center w-full leading-[180%] sm:block lg:hidden text-black/[.80]">
              We harness cutting-edge technologies to create seamless websites.
              Our process focuses on efficiency, security, and scalability,
              ensuring optimal digital experiences for our clients and users
              alike.
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-10">
          {Tech.map((index) => (
            <div key={index.id}>
              <img src={index.url} alt={index.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
