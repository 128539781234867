import React from "react";

export default function Process() {
  const DotStyle =
    "lg:w-[15px] lg:h-[15px] md:w-[12px] md:h-[12px] bg-secondary rounded-full lg:ml-[17px] md:ml-[10px] sm:hidden md:block";
  const VerLine =
    "bg-black/[.20] h-[60px] w-[1px] lg:ml-[24px] md:ml-[16px] sm:hidden md:block";
  const Number =
    "font-title text-secondary sm:text-smalltitle lg:text-title sm:text-center md:text-left";
  const Title =
    "font-title text-primary lg:text-subtitle sm:text-smallsubtitle sm:text-center md:text-left";
  const Description =
    "font-body text-body leading-[180%] text-black/[.80] sm:text-center md:text-left";

  return (
    <div className="flex justify-center sm:px-4 lg:px-20 py-10">
      <div className="max-w-default w-full sm:space-y-4 lg:space-y-6">
        <div className="flex justify-center">
          <h2 className="text-center font-title text-primary sm:text-smalltitle lg:text-title w-full max-w-[50rem]">
            We work for better growing result step by step
          </h2>
        </div>

        <div className="flex justify-center">
          <p className="text-center text-body font-body text-center w-full max-w-[40rem] leading-[180%] text-black/[.80]">
            Explore the journey of our website's development, witnessing the
            careful progression we've made towards achieving growth and
            excellence. Each milestone signifies our dedication to crafting a
            seamless user experience and delivering exceptional results. Join us
            as we pave the path to success, one step at a time
          </p>
        </div>

        <div>
          <div className="bg-black/[.20] h-[1px] sm:hidden md:block" />
          <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4">
            <div className="space-y-2">
              <div>
                <div className={VerLine} />
                <div className={DotStyle} />
                <h2 className={Number}>01</h2>
              </div>

              <p className={Title}>Market Research</p>
              <p className={Description}>
                Dive deep into market trends, user demographics, and competitor
                analysis to identify opportunities and create a website strategy
                that resonates with the target audience. Through comprehensive
                research, we ensure that our clients' websites are positioned
                strategically for success.
              </p>
            </div>

            <div className="space-y-2">
              <div>
                <div className={VerLine} />
                <div className={DotStyle} />
                <h2 className={Number}>02</h2>
              </div>

              <p className={Title}>UI/UX Design</p>
              <p className={Description}>
                Craft visually stunning and intuitively designed interfaces that
                prioritize user experience. From wireframing to prototyping, our
                designers collaborate closely with clients to create engaging
                and user-friendly website designs that captivate and retain
                visitors.
              </p>
            </div>

            <div className="space-y-2">
              <div>
                <div className={VerLine} />
                <div className={DotStyle} />
                <h2 className={Number}>03</h2>
              </div>

              <p className={Title}>App Development</p>
              <p className={Description}>
                Employ cutting-edge technologies and best practices to develop
                robust and scalable websites that meet the needs of our clients.
                We implement agile methodologies to guarantee smooth coding,
                seamless integration, and peak performance across various
                devices and platforms.
              </p>
            </div>

            <div className="space-y-2">
              <div>
                <div className={VerLine} />
                <div className={DotStyle} />
                <h2 className={Number}>04</h2>
              </div>

              <p className={Title}>Quality Assurance</p>
              <p className={Description}>
                Conduct rigorous testing protocols to identify and rectify any
                bugs, errors, or usability issues before launch. Through
                meticulous quality assurance processes, we guarantee that our
                clients' websites are flawless, functional, and deliver an
                unparalleled user experience.
              </p>
            </div>

            <div className="space-y-2">
              <div>
                <div className={VerLine} />
                <div className={DotStyle} />
                <h2 className={Number}>05</h2>
              </div>

              <p className={Title}>Deployment</p>
              <p className={Description}>
                Seamlessly deploy the website onto the client's server, ensuring
                a smooth transition from development to production. Our
                deployment process is meticulously planned and executed to
                minimize downtime and ensure that the website is live and
                accessible to users worldwide.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
