import React from "react";

export default function Delivery() {
  const TextStyle =
    "font-body text-body leading-[180%] text-black/[.80] sm:text-center md:text-left";
  const ImageStyle = "h-[2.5rem]";
  const ItemStyle = "w-full space-y-4";

  return (
    <div className="flex justify-center sm:px-4 lg:px-20 pb-20 bg-primary/[.10] py-20">
      <div className="max-w-default lg:flex sm:block items-center w-full sm:space-y-10 lg:space-y-6 lg:space-x-40">
        <div className="w-full space-y-6">
          <div className="">
            <img
            className="w-[10rem]"
              src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Delivery%20logos%2Fdelivery_man.svg?alt=media&token=cb317561-6da4-4e1c-9000-c8c1b650520d"
              alt=""
            />
          </div>
          <div className="w-full flex lg:justify-start sm:justify-center">
            <h1 className="w-full max-w-[30rem] font-title lg:text-title sm:text-smalltitle text-primary sm:text-center lg:text-left">
              Deliver your product with our delivery partners
            </h1>
          </div>

          <div className="w-full flex sm:justify-center lg:justify-start">
            <p className="text-body font-body w-full leading-[180%] sm:text-center lg:text-left w-full max-w-[40rem] text-black/[.80]">
              Trust us to deliver your products swiftly and securely with our
              trusted delivery partners, ensuring seamless service for your
              e-commerce business. From checkout to doorstep, our seamless
              integration ensures a hassle-free experience for you and your
              customers. Let us handle the logistics so you can focus on growing
              your online venture.
            </p>
          </div>
        </div>

        <div className="w-full grid md:grid-cols-2 gap-10">
          <div className={ItemStyle}>
            <div className="w-full flex sm:justify-center md:justify-start">
              <img
                className={ImageStyle}
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Delivery%20logos%2FDHL.png?alt=media&token=98057607-c39b-4de2-b1cc-34816d7eea6d"
                alt=""
              />
            </div>

            <p className={TextStyle}>
              DHL is known for its exceptional tracking services and provides
              expedited door-to-door delivery to more than 220 countries,
              typically within 1-3 business days, ideal for time-sensitive
              shipments.
            </p>
          </div>

          <div className={ItemStyle}>
            <div className="w-full flex sm:justify-center md:justify-start">
              <img
                className={ImageStyle}
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Delivery%20logos%2FFedEx.png?alt=media&token=d7157e50-fa8f-46fc-b192-d4cf3b36bcc9"
                alt=""
              />
            </div>
            <p className={TextStyle}>
              FedEx ensures urgent shipments reach their destination promptly.
              They also provide advanced packaging and insurance options to
              protect valuable items during transit.
            </p>
          </div>

          <div className={ItemStyle}>
            <div className="w-full flex sm:justify-center md:justify-start">
              <img
                className={ImageStyle}
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Delivery%20logos%2FDomex.png?alt=media&token=9afa6c5a-f449-4aaa-a705-45c00547c1c0"
                alt=""
              />
            </div>
            <p className={TextStyle}>
              Domex prioritizes customer satisfaction with flexible delivery and
              personalized service options. They specialize in handling fragile
              items for safe transportation and delivery.
            </p>
          </div>

          <div className={ItemStyle}>
            <div className="w-full flex sm:justify-center md:justify-start">
              <img
                className={ImageStyle}
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Delivery%20logos%2FAramex.png?alt=media&token=241fa679-fbf4-4da4-93ad-5d2c8b481bc1"
                alt=""
              />
            </div>
            <p className={TextStyle}>
              Aramex thrives in emerging markets, utilizing local expertise for
              efficient shipping solutions. They offer innovative last-mile
              delivery like Aramex Spot, allowing customers to specify pickup or
              delivery locations.
            </p>
          </div>

          <div className={ItemStyle}>
            <div className="w-full flex sm:justify-center md:justify-start">
              <img
                className={ImageStyle}
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/Delivery%20logos%2FUPS.png?alt=media&token=bb846a68-c25f-4703-b4de-b65105892f2d"
                alt=""
              />
            </div>
            <p className={TextStyle}>
              UPS My Choice lets customers customize delivery preferences, while
              UPS Access Point network offers convenient pickup and drop-off
              locations at stores, providing flexibility and convenience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
