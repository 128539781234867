import React from "react";

// Higher-Order Component for Hero
const Services = ({ title, description, mobile_description, data }) => {

    return (
        <div className="flex justify-center sm:px-4 lg:px-20 pb-20">
            <div className="max-w-default w-full sm:space-y-4 lg:space-y-6">
                <h1 className="text-center font-title lg:text-title sm:text-smalltitle text-primary">
                    {title}
                </h1>
                <div className="flex justify-center">
                    <div className="max-w-subtitle w-full">
                        <p className="text-center text-body font-body text-center w-full leading-[180%] sm:hidden md:hidden lg:block text-black/[.80]">
                            {description}
                        </p>

                        <p className="text-center text-body font-body text-center w-full leading-[180%] sm:block md:block lg:hidden text-black/[.80]">
                            {mobile_description}
                        </p>
                    </div>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 lg:gap-20 md:gap-4 sm:gap-4">
                    {data.map((index) => (
                        <div key={index.id}>
                            <div className="space-y-2">
                                <img src={index.img} alt={index.name} />
                                <p className="font-title text-primary lg:text-subtitle sm:text-smallsubtitle">
                                    {index.name}
                                </p>
                                <p className="font-body text-body leading-[180%] text-black/[.80]">
                                    {index.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
