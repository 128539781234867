import React from "react";
import Header from "../nav/Header";
import { useNavigate } from "react-router-dom";

// Higher-Order Component for Hero
const TopHero = ({
  title,
  description,
  buttonText,
  buttonLink,
  imageUrl,
  mobile_description,
  ButtonVisibility,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/abstract-wavy-shapes%201.jpg?alt=media&token=81bc5b3d-8a97-487f-8b99-ac3b42bbf9b1")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="flex items-center justify-center sm:px-4 lg:px-20 h-[30rem] pt-10"
      >
        <div className="max-w-default w-full">
          <div className="flex items-center">
            <div className="text-primary font-title sm:space-y-8 lg:space-y-4 w-full sm:pt-0 lg:pt-0">
              <p className="sm:text-[1.8rem] md:text-[2rem] lg:text-title sm:text-center md:text-center lg:text-left w-full max-w-[40rem]">
                {title}
              </p>
              <p className="w-full max-w-[750px] text-body font-body leading-[180%] sm:hidden md:hidden lg:block">
                {description}
              </p>
              <p className="w-full max-w-[750px] text-body font-body leading-[180%] sm:block md:block lg:hidden text-center">
                {mobile_description}
              </p>
              {ButtonVisibility === "true" && (
                <div className="flex items-center sm:justify-center md:justify-center lg:justify-start space-x-4 text-body">
                  <div
                    onClick={() => {
                      navigate(buttonLink);
                      window.scrollTo(0, 0);
                    }}
                    className="bg-primary text-white p-3 px-7 rounded-md hover:cursor-pointer"
                  >
                    <p>{buttonText}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="sm:hidden md:hidden lg:block">
              <img className="h-[50rem]" src={imageUrl} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHero;
