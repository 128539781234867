import React from "react";
import WhatCanYouGet from "../../json/DevAbleTo.json"

export default function DevAbleTo() {
  return (
    <div className="flex justify-center sm:px-4 lg:px-20 lg:py-20 sm:py-10">
      <div className="w-full max-w-default">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 lg:gap-20 md:gap-4 sm:gap-4">
          {WhatCanYouGet.slice(0,4).map((index) => (
            <div key={index.id}>
              <div className="space-y-2">
                <img src={index.img} alt={index.name} />
                <p className="font-title text-primary lg:text-subtitle sm:text-smallsubtitle">
                  {index.name}
                </p>
                <p className="font-body text-body leading-[180%] text-black/[.80]">
                  {index.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
