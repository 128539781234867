import React from "react";
import TopHero from "../components/high_order_components/TopHero";
import GlobalNetwork from "../components/high_order_components/GlobalNetwork";
import countries from "../json/Countries.json";
import Footer from "../components/Footer";
import DetailOne from "../components/high_order_components/DetailOne";
import DetailTwo from "../components/high_order_components/DetailTwo";

export default function AboutUs() {
  return (
    <div>
      <TopHero
        title="Beyond Code: Building Digital Success"
        description="Enhance your digital experience with our dynamic team of software developers. We excel in crafting websites and mobile applications, effortlessly fusing e-commerce with digital marketing tactics. Let us boost your online visibility with our e-business and digital marketing services."
        mobile_description="Enhance your digital experience with our dynamic team of software developers. We excel in crafting websites and mobile applications, effortlessly fusing e-commerce with digital marketing tactics. Let us boost your online visibility with our e-business and digital marketing services."
        buttonText="Contact Us"
        buttonLink=""
        ButtonVisibility="true"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/homehero.svg?alt=media&token=63eb5dd6-49df-44d6-aead-4d90ecf0de09"
      />
      <GlobalNetwork
        title="Our Global Network"
        description="Explore a rich tapestry of clients, ranging from dynamic startups to venerable industry stalwarts, all of whom entrust Fitwin with their digital needs. Join this thriving community of businesses and experience the transformative power of our top-tier solutions."
        mobile_description="Explore a rich tapestry of clients, ranging from dynamic startups
        to venerable industry stalwarts, all of whom entrust Fitwin with
        their digital needs."
        data={countries}
      />
      <DetailTwo
        title="Digital Marketing & Business Growth"
        p1="Aligning to the modern needs of application development, we use the power of innovation and leverage the most robust technologies to build high-performance, secure, and responsive applications that run seamlessly across all platforms. ​"
        p2="All mobile applications are built based on profound market research and a thorough understanding of our client's business case. We create these native apps with enhanced technology features, faster load application times, and responsive navigation."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Flight-bulb-concept-illustration_114360-4416.jpg?alt=media&token=cecc09b1-b82d-4673-92bf-ecae78d43db2"
      />
      <DetailOne
        title="Artificial Intelligence Development & Integration"
        p1="Cloud Solutions involve leveraging cloud computing technologies to deliver various computing services over the internet, including storage, servers, databases, networking, software, and analytics. Companies utilize platforms like AWS, Azure, and GCP instead of maintaining physical hardware. Cloud Solutions offer scalability, flexibility, cost-effectiveness, and accessibility, enabling businesses to innovate faster and focus on our Client."
        p2="Project Maintenance, on the other hand, is the ongoing process of managing and updating software applications or systems after their initial development and deployment. It involves tasks such as bug fixes, security patches, performance optimization, feature enhancements, and compliance updates. Effective project maintenance ensures that software remains functional, secure, and aligned with evolving business needs over time"
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fai-powered-content-creation-isometric-concept-with-chatbot-laptop-screen-3d-vector-illustration_1284-82523.jpg?alt=media&token=52709bcf-c4f4-4177-b4b9-7dd654ff6ae6"
      />
      <Footer />
    </div>
  );
}
