import React from 'react'
import Footer from '../components/Footer'
import YourProject from '../components/YourProject'
import TopHero from '../components/high_order_components/TopHero'
import Clients from "../json/Clients.json"
import OurClients from '../components/high_order_components/OurClients'
import Services from "../components/high_order_components/Services";
import Services_data from "../json/MarketingServices.json"
import DetailOne from '../components/high_order_components/DetailOne'
import DetailTwo from '../components/high_order_components/DetailTwo'

export default function DigitalMarketing() {
  return (
    <div className="sm:space-y-2 md:space-y-6 lg:space-y-8">
      <TopHero
        title="Right Marketing Solutions For Your Next Success"
        description="Unlock the potential of your online business with our tailored
        e-commerce solutions. From seamless user experiences to robust
        backend management, we empower your digital storefront to
        thrive."
        mobile_description="Unlock the potential of your online business with our tailored
        e-commerce solutions."
        buttonText="Let's Get Started"
        buttonLink="/book-an-appointment"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/contact_hero_img.svg?alt=media&token=c86260ec-9cfe-4357-ad7d-ab4efefda775"
        ButtonVisibility="true"
      />
      <Services
        title="What We Do"
        description="Our committed team of developers, designers, and marketers is
        focused on delivering exceptional solutions tailored to your
        needs. Utilizing cutting-edge technologies, we craft responsive
        websites and intuitive mobile apps to provide seamless user
        experiences that captivate and connect with your audience."
        mobile_description="Our committed team of developers, designers, and marketers is
        focused on delivering exceptional solutions tailored to your
        needs.F"
        data={Services_data}
      />
      <OurClients
        title="Our Featured Clients"
        description="Explore the diverse range of clients who have placed their trust
        in Fitwin, spanning from burgeoning startups to established
        industry leaders. Their success stories serve as proof of our
        dedication to delivering top-tier digital solutions. Join the
        community of thriving businesses by partnering with us."
        mobile_description="Explore the diverse range of clients who have placed their trust
        in Fitwin, spanning from burgeoning startups to established
        industry leaders."
        data={Clients}
        bg="1"
      />
      <DetailOne
        title="Host Game Changing Marketing Campaigns"
        p1="Cloud Solutions involve leveraging cloud computing technologies to deliver various computing services over the internet, including storage, servers, databases, networking, software, and analytics. Companies utilize platforms like AWS, Azure, and GCP instead of maintaining physical hardware. Cloud Solutions offer scalability, flexibility, cost-effectiveness, and accessibility, enabling businesses to innovate faster and focus on our Client."
        p2="Project Maintenance, on the other hand, is the ongoing process of managing and updating software applications or systems after their initial development and deployment. It involves tasks such as bug fixes, security patches, performance optimization, feature enhancements, and compliance updates. Effective project maintenance ensures that software remains functional, secure, and aligned with evolving business needs over time"
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fdevops-team-abstract-concept-vector-illustration-software-development-team-member-agile-workflow-devops-team-model-it-teamwork-project-management-integrated-practice-abstract-metaphor_335657-2299.avif?alt=media&token=0d7ed965-2d9d-456a-b1bf-db222132aad6"
      />
      <DetailTwo
        title="Target Right Audience for Your Product"
        p1="Aligning to the modern needs of application development, we use the power of innovation and leverage the most robust technologies to build high-performance, secure, and responsive applications that run seamlessly across all platforms. ​"
        p2="All mobile applications are built based on profound market research and a thorough understanding of our client's business case. We create these native apps with enhanced technology features, faster load application times, and responsive navigation."
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fapp-development-illustration.jpg?alt=media&token=a57e76ed-7c3a-4642-b23f-5df90f131e19"
      />
      <DetailOne
        title="Increese Your Sales Using Marketing"
        p1="Cloud Solutions involve leveraging cloud computing technologies to deliver various computing services over the internet, including storage, servers, databases, networking, software, and analytics. Companies utilize platforms like AWS, Azure, and GCP instead of maintaining physical hardware. Cloud Solutions offer scalability, flexibility, cost-effectiveness, and accessibility, enabling businesses to innovate faster and focus on our Client."
        p2="Project Maintenance, on the other hand, is the ongoing process of managing and updating software applications or systems after their initial development and deployment. It involves tasks such as bug fixes, security patches, performance optimization, feature enhancements, and compliance updates. Effective project maintenance ensures that software remains functional, secure, and aligned with evolving business needs over time"
        img="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/images%2Fdevops-team-abstract-concept-vector-illustration-software-development-team-member-agile-workflow-devops-team-model-it-teamwork-project-management-integrated-practice-abstract-metaphor_335657-2299.avif?alt=media&token=0d7ed965-2d9d-456a-b1bf-db222132aad6"
      />
      <YourProject />
      <Footer />
    </div>
  )
}
