import React from "react";
import TechnologiesWeUse from "../components/home/TechnologiesWeUse";
import Community from "../components/home/Community";
import YourProject from "../components/YourProject";
import Footer from "../components/Footer";
import OurWork from "../components/home/OurWork";
import TopHero from "../components/high_order_components/TopHero";
import OurClients from "../components/high_order_components/OurClients";
import Clients from "../json/Clients.json";
import Services from "../components/high_order_components/Services";
import Services_data from "../json/Services.json";
import countries from "../json/Countries.json";
import GlobalNetwork from "../components/high_order_components/GlobalNetwork";

export default function Home() {
  return (
    <div className="sm:space-y-2 md:space-y-6 lg:space-y-8">
      <TopHero
        title="Beyond Code: Building Digital Success"
        description="Enhance your digital experience with our dynamic team of software developers. We excel in crafting websites and mobile applications, effortlessly fusing e-commerce with digital marketing tactics. Let us boost your online visibility with our e-business and digital marketing services."
        mobile_description="Enhance your digital experience with our dynamic team of software developers. We excel in crafting websites and mobile applications, effortlessly fusing e-commerce with digital marketing tactics. Let us boost your online visibility with our e-business and digital marketing services."
        buttonText="Contact Us"
        buttonLink="/contactus"
        imageUrl="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/homehero.svg?alt=media&token=63eb5dd6-49df-44d6-aead-4d90ecf0de09"
        ButtonVisibility="true"
      />
      <Services
        title="What We Do"
        description="Our committed team of developers, designers, and marketers is
        focused on delivering exceptional solutions tailored to your
        needs. Utilizing cutting-edge technologies, we craft responsive
        websites and intuitive mobile apps to provide seamless user
        experiences that captivate and connect with your audience."
        mobile_description="Our committed team of developers, designers, and marketers is
        focused on delivering exceptional solutions tailored to your
        needs.F"
        data={Services_data}
      />
      <OurClients
        title="Our Featured Clients"
        description="Explore the diverse range of clients who have placed their trust
        in Fitwin, spanning from burgeoning startups to established
        industry leaders. Their success stories serve as proof of our
        dedication to delivering top-tier digital solutions. Join the
        community of thriving businesses by partnering with us."
        mobile_description="Explore the diverse range of clients who have placed their trust
        in Fitwin, spanning from burgeoning startups to established
        industry leaders."
        data={Clients}
        bg="1"
      />
      <GlobalNetwork
        title="Our Global Network"
        description="Explore a rich tapestry of clients, ranging from dynamic startups to venerable industry stalwarts, all of whom entrust Fitwin with their digital needs. Join this thriving community of businesses and experience the transformative power of our top-tier solutions."
        mobile_description="Explore a rich tapestry of clients, ranging from dynamic startups
        to venerable industry stalwarts, all of whom entrust Fitwin with
        their digital needs."
        data={countries}
      />
      <Community />
      <TechnologiesWeUse />
      <OurWork />
      <YourProject />
      <Footer />
    </div>
  );
}
