import React from "react";
import Header from "../components/nav/Header";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div
      className="h-screen"
      style={{
        backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/abstract-wavy-shapes%201.jpg?alt=media&token=81bc5b3d-8a97-487f-8b99-ac3b42bbf9b1")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header/>
      <div className="flex h-[75%] items-center justify-center sm:px-4 lg:px-20">
        <div className="max-w-default w-full">
          <div className="lg:h-[80%] flex items-center">
            <div className="text-primary font-title sm:space-y-8 lg:space-y-4 w-full sm:pt-0 lg:pt-0">
              <p className="sm:text-[1.8rem] md:text-[2rem] lg:text-title sm:text-center md:text-center lg:text-left">
                Page Not Fond
              </p>
              <p className="w-full max-w-[550px] text-body font-body leading-[180%]">
                page that you are looking for couldn't be found, You might
                entered wrong url or url permanently removed from website
              </p>
              <div className="flex items-center sm:justify-center md:justify-center lg:justify-start space-x-4 text-body cursor-pointer">
                <div
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0); // Scroll to the top of the page after navigation
                  }}
                  className="flex items-center bg-primary text-white p-3 px-7 rounded-md space-x-4"
                >
                  <FaHome className="text-[1.2rem]" />
                  <p>Back to home</p>
                </div>
              </div>
            </div>
            <div className="sm:hidden md:hidden lg:block">
              <img
                className="h-[60rem]"
                src="https://firebasestorage.googleapis.com/v0/b/portfolios-d3d5a.appspot.com/o/404-img%20(1).svg?alt=media&token=d96dfef0-394f-4c01-8be5-7afe18c134d2"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
