import React, { useEffect, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

export default function MobileMenu({ onClose, MenuItems, popupVisible }) {
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [onClose]);

  return (
    <div ref={menuRef} className={`h-screen sm:block lg:hidden fixed top-0 duration-500 bg-white w-[80%] p-4 z-10 space-y-4 ${popupVisible ? "right-0" : "right-[-60rem]"}`}>
      <div className="flex w-full justify-end text-[2.5rem]">
        <IoIosClose onClick={onClose} />
      </div>
      <div>
        {MenuItems.map((index) => (
          <div
            key={index.id}
            onClick={() => {
              navigate(index.url);
              window.scrollTo(0, 0);
              onClose(); // Close the menu after navigation
            }}
            className={`cursor-pointer hover:text-secondary p-4 rounded-lg ${
              location.pathname === index.url ? "bg-secondary/[.10]" : ""
            }`}
          >
            <p>{index.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
